<template>
  <div class="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div v-show="isLoading" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
      <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
    </div>
    <div class="max-w-md w-full space-y-8">
      <div>
        <!--        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />-->
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          登入你的帳戶
        </h2>
      </div>
      <form  class="mt-8 space-y-6" action="#" method="POST" @submit.prevent="handleLogin">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Username</label>
            <input v-model="username" id="email-address" name="username" type="text" autocomplete="email" required="" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="使用者名稱" />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="使用者密碼" />
          </div>
        </div>
        <div>
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            登入
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import UserService from '@/services/user.service'
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      isLoading: false,
      fullPage: false,
      loader: 'bars'
    }
  },
  created: () => {
    // 這個會隨時鎖定，是否是授權狀態，computed針對資料有這樣的特性在
    // console.log('Auth!!!')
    if (localStorage.getItem('user')) {
      // console.log('Login redirect')
      // console.log(localStorage.getItem('user'))
    }
    // UserService.getAuthContent().then(
    //   res => {
    //     this.content = res.data
    //     // 之後要改成取得資料就關閉，現在是測試
    //     // setTimeout(() => { this.isLoading = false }, 1200)
    //     // console.log('Login redirect')
    //   }
    // ).catch(() => {
    //   // console.log('Auth err!!!')
    // })
  },
  computed: {
    // 這個會隨時鎖定，是否是授權狀態，computed針對資料有這樣的特性在
    currentUser () {
      return this.$store.state.auth.user
    }
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    handleLogin () {
      this.isLoading = true
      const user = {
        username: this.username,
        password: this.password
      }
      // 他是auth module中的login action，因為我們這的vuex是以auth module方式引入
      // 這下面寫法看起來會很奇妙，那是因為action本身就是一個async任務，這邊可以一種promise方式來運作他
      // then可以做成功的操作、catch可以做失敗的操作
      this.$store.dispatch('auth/login', user).then(
        () => {
          // console.log('Authing')
          this.$router.push({ name: 'Platform' })
          // catch也可以err => { // console.log(err) 不過內容會比較難懂，下面可以this.message給前端顯示說帳號或密碼錯誤
        }).catch(() => {
        // console.log('Auth err')
        this.isLoading = false
      })
    },
    onCancel () {
      // console.log('User cancelled the loader.')
    }
  }
}
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
