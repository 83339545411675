import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem('user')
      // console.log('Routes!!!!')
      if (!loggedIn) {
        // 若不成立則回到login登入頁面
        next()
      } else {
        // 有驗證就進到該頁面
        next('/platform')
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem('user')
      // console.log('Routes!!!!')
      if (!loggedIn) {
        // 若不成立則回到login登入頁面
        next()
      } else {
        // 有驗證就進到該頁面
        next('/platform')
      }
    }
  },
  {
    path: '/platform',
    name: 'Platform',
    component: () => import('../views/Platform')
  },
  {
    path: '/action',
    name: 'Action',
    component: () => import('../views/Action')
  },
  {
    path: '/carbon',
    name: 'Carbon',
    component: () => import('../views/Carbon')
  },
  {
    path: '/node',
    name: 'Node',
    component: () => import('../views/Node')
  }
  // {
  //   path: '/action',
  //   name: 'Action',
  //   component: () => import('../views/Action')
  // }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/']
  // 取得是否為需要Auth的routes的bool，不是public則為需要Auth: true、反之false
  const authRequired = !publicPages.includes(to.path)
  // 看當前是否有localstorage存放(token)
  // 有一種情況會沒有token，logout登出
  const loggedIn = localStorage.getItem('user')
  // 若authRequired true且!loggedIn true，表示需要授權，但沒有token存在localStorage情況，需要登入
  if (authRequired && !loggedIn) {
    // console.log('check0')
    // 若不成立則回到login登入頁面
    next('/login')
  } else {
    if (loggedIn) {
      // 需要auth頁面，但有授權token情況，會需要驗證看是否保有時效
      const JSONLoggedIn = JSON.parse(loggedIn)
      // golang的unix是second為unit、而js則是毫秒，因此要除一千才能比較
      const timeNow = Math.ceil(new Date().getTime() / 1000)
      const expireTime = JSONLoggedIn.expireTime
      // console.log(timeNow - expireTime)
      // console.log(authRequired)
      // console.log(authRequired && loggedIn)
      // 下面是超時情況，會回到登入頁面
      if (timeNow > expireTime) {
        // console.log('check2')
        // 並且移除超時的授權token
        localStorage.removeItem('user')
        // 也順便移除app後端之驗證
        localStorage.removeItem('user-app')
        next('/login')
      } else {
        // token授權未超時，因此可以進到目標頁面
        // console.log('check4')
        // 有驗證就進到該頁面
        next()
      }
    } else {
      // 這是非授權頁面，"登入頁面"，直接next()進入目標頁面
      // console.log('check5')
      next()
    }
  }
})

export default router
