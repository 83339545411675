class Config {
  constructor () {
    this.graphBackend = 'https://www.test-graph.ntu.asia/'
    this.basicBackendBaseURL = 'https://www.test-backend.ntu.asia/'
    this.appBackendBaseURL = 'https://www.test-app.ntu.asia/'
    this.recordGetMaxAmountBasedOnBackendSet = 10
  }
}

export default new Config()
