<template>
  <div class="w-3/12">
    <router-link :to="{name: 'Home'}">
      <img :src="img_source" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data: function () {
    return {
      img_source: require('../assets/icons/en_logo.png')
    }
  }
}
</script>

<style scoped>

</style>
