<template>
  <nav class="flex justify-center text-2xl">
    <ul class="flex text-o_purple-200 font-semibold items-end capitalize mr-4  list-none">
<!--      <li v-if="!currentUser" class="rounded-sm  py-2 mr-12 hover:underline">-->
<!--        <router-link :to="{name: 'Login'}">-->
<!--          Login-->
<!--        </router-link>-->
<!--      </li>-->
      <li v-if="currentUser" class="rounded-sm  py-2 mr-2 hover:underline">
        <router-link :to="{name: 'Platform'}">
          履歷/
        </router-link>
      </li>
      <li v-if="currentUser" class="rounded-sm  py-2 mr-2 hover:underline">
        <router-link :to="{name: 'Node'}">
          圖譜/
        </router-link>
      </li>
      <li v-if="currentUser" class="rounded-sm  py-2 mr-2 hover:underline">
        <router-link :to="{name: 'Action'}">
          標籤/
        </router-link>
      </li>
      <li v-if="currentUser" class="rounded-sm  py-2 mr-2 hover:underline">
        <router-link :to="{name: 'Carbon'}">
          碳計算/
        </router-link>
      </li>
      <!-- 栽培曆也可以留待下期 -->
      <a v-if="currentUser" target="_blank" href="https://www.text-cal-vbf1.ntu.asia/" class="rounded-sm  py-2 mr-2 hover:underline">
        栽培曆/
      </a>
<!--      <li v-if="currentUser" class="rounded-sm  py-2 mr-5 hover:underline">-->
<!--        <router-link :to="{name: 'Action'}">-->
<!--          項目-->
<!--        </router-link>-->
<!--      </li>-->
      <li v-if="currentUser" class="rounded-sm  py-2 mr-12 hover:underline">
        <a @click.prevent="logOut" class="cursor-pointer">
          登出
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  data: function () {
    return {
    }
  },
  methods: {
    logOut () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    }
  },
  computed: {
    // 這個會隨時鎖定，是否是授權狀態，computed針對資料有這樣的特性在
    currentUser () {
      return this.$store.state.auth.user
    }
  }
}
</script>

<style scoped>

</style>
