<template>
  <div id="app" class="bg-o_pink px-10 pt-10 font-urw_form_family font-normal" @click="logOutCheck">
    <Canvas class="">
      <template v-slot:header>
        <header class="flex justify-between items-end p-5 border-b">
          <Logo class="ml-6 mt-6 mb-1" />
<!--          <h1 class="text-3xl text-o_purple-200 font-semibold pr-50 mb-10">動態知識區塊鏈-Monitor</h1>-->
          <Navbar />
        </header>
      </template>
      <template v-slot:main>
        <main class="px-12 mt-3">
          <router-view class="mb-5"/>
        </main>
      </template>
      <template v-slot:footer>
        <footer class="px-12">
          <Footer/>
        </footer>
      </template>
    </Canvas>
  </div>
</template>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fe917d;
}
//.custom-h-inside {
//  height: 700px;
//}
.content_size {
  height: 60vh;
}
</style>
<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import Canvas from '@/views/Canvas'
import Logo from '@/components/Logo'

export default {
  components: { Logo, Canvas, Footer, Navbar },
  methods: {
    logOutCheck () {
      // 這做法應該是可以接受，因為其實操作都是按按鈕，若過期則不給操作，也部會說像是定時一直再做
      // 再則是說點擊這頻率也不適說高到很離譜，未來若覺得操作frequency太高，這資源吃太慘，只需要註解此method，與事件
      const loggedIn = localStorage.getItem('user')
      if (loggedIn) {
        const JSONLoggedIn = JSON.parse(loggedIn)
        const expireTime = JSONLoggedIn.expireTime
        const timeNow = Math.ceil(new Date().getTime() / 1000)
        const expireCheck = timeNow - expireTime
        // console.log('show the remaining expire time: ' + expireCheck)
        // --------------------------------------------------
        if (expireCheck > 0) {
          // console.log('there is no remaining time for using auth')
          this.$store.dispatch('auth/logout')
          this.$router.push('/login')
        }
      }
    }
  }
}
</script>
